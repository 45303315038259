<template>
  <div class="v-stack h-stretch gap-3">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="header">
          <div>Add Pricelist</div>
        </div>
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-3">
            <label class="text-left">Name</label>
            <input v-model="newPricelistName" type="text" placeholder="name" />
            <label class="text-left">Base Pricelist</label>
            <select v-model="basePricelist">
              <option v-for="pricelist in basePricelists" :key="pricelist._id" :value="pricelist">
                {{ pricelist.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="addTemplatePricelist()" class="edit">Add</button>
        </div>
      </div>
    </div>
    <div class="h-stack h-end">
      <button class="add" @click="addDialog()">Add</button>
    </div>
    <Table :items="templates" v-on:rowSelected="$router.push('/pricelist/list/detail/' + $event._id)"
      css="1fr 120px 120px 120px 120px" :columns="[
        {
          name: 'Name',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Production Fee',
          sort: 'numeric',
          path: ['productionFeePercent'],
          format: '%',
        },
        {
          name: 'Gear Discount',
          sort: 'numeric',
          path: ['discountPercent'],
          format: '%',
        },
        {
          name: 'Budget',
          sort: 'numeric',
          path: ['totalPrice'],
          format: 'czk',
          formatType: 'currency',
        },
        {
          name: 'Budget VAT',
          sort: 'numeric',
          path: ['totalPriceWithVat'],
          format: 'czk',
          formatType: 'currency',
        },
      ]"></Table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "@/components/Table.vue";
import utils from "@/utils.js";

export default {
  props: ["id"],
  data() {
    return {
      templates: [],
      newPricelistName: "",
      basePricelist: null,
      basePricelists: [],
    };
  },
  components: {
    Table,
  },
  methods: {
    ...mapActions(["addPricelist", "getPricelists"]),
    addDialog() {
      this.newPricelistName = "";
      this.$refs.overlay.classList.add("show");
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
    addTemplatePricelist() {
      if (this.newPricelistName.length > 0 && this.basePricelist) {
        this.addPricelist({
          name: this.newPricelistName,
          base: false,
          template: true,
          basePricelist: this.basePricelist._id,
        })
          .then(() => {
            this.refresh();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.dismiss();
    },
    refresh() {
      this.getPricelists().then((pricelists) => {
        this.basePricelists = pricelists
          .filter((item) => item.base)
          .sort((a, b) => {
            return a.name.localeCompare(b.name, "cs");
          });
        this.templates = pricelists.filter((item) => item.template);

        for (const pricelist of this.templates) {
          pricelist.totalPrice = utils.pricelist.totalPrice(pricelist);
          pricelist.totalPriceWithVat =
            utils.pricelist.totalPriceWithVat(pricelist);
        }
      }).catch((error) => {
        console.log(error)
      });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
