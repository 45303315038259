var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-stack h-stretch gap-3"},[_c('div',{ref:"overlay",staticClass:"overlay"},[_c('div',{staticClass:"card dynamic"},[_vm._m(0),_c('div',{staticClass:"content mt-3"},[_c('div',{staticClass:"v-stack h-stretch gap-3"},[_c('label',{staticClass:"text-left"},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPricelistName),expression:"newPricelistName"}],attrs:{"type":"text","placeholder":"name"},domProps:{"value":(_vm.newPricelistName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.newPricelistName=$event.target.value}}}),_c('label',{staticClass:"text-left"},[_vm._v("Base Pricelist")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.basePricelist),expression:"basePricelist"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.basePricelist=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.basePricelists),function(pricelist){return _c('option',{key:pricelist._id,domProps:{"value":pricelist}},[_vm._v(" "+_vm._s(pricelist.name)+" ")])}),0)])]),_c('div',{staticClass:"footer gap-3 h-space-between mt-3"},[_c('button',{staticClass:"submit",on:{"click":function($event){return _vm.dismiss()}}},[_vm._v("Cancel")]),_c('button',{staticClass:"edit",on:{"click":function($event){return _vm.addTemplatePricelist()}}},[_vm._v("Add")])])])]),_c('div',{staticClass:"h-stack h-end"},[_c('button',{staticClass:"add",on:{"click":function($event){return _vm.addDialog()}}},[_vm._v("Add")])]),_c('Table',{attrs:{"items":_vm.templates,"css":"1fr 120px 120px 120px 120px","columns":[
      {
        name: 'Name',
        sort: 'alphabet',
        path: ['name'],
      },
      {
        name: 'Production Fee',
        sort: 'numeric',
        path: ['productionFeePercent'],
        format: '%',
      },
      {
        name: 'Gear Discount',
        sort: 'numeric',
        path: ['discountPercent'],
        format: '%',
      },
      {
        name: 'Budget',
        sort: 'numeric',
        path: ['totalPrice'],
        format: 'czk',
        formatType: 'currency',
      },
      {
        name: 'Budget VAT',
        sort: 'numeric',
        path: ['totalPriceWithVat'],
        format: 'czk',
        formatType: 'currency',
      } ]},on:{"rowSelected":function($event){return _vm.$router.push('/pricelist/list/detail/' + $event._id)}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('div',[_vm._v("Add Pricelist")])])}]

export { render, staticRenderFns }